/* #region flex row */

@mixin flexbox-row() {
    display: flex;
    flex-direction: row;
}

@mixin flexbox-row--centered() {
    @include flexbox-row();
    align-items: center;
}

@mixin flexbox-row--last-baseline() {
    @include flexbox-row();
    align-items: last baseline;
}

@mixin flexbox-row--center() {
    @include flexbox-row();
    justify-content: center;
}

@mixin flexbox-row--center-centered {
    @include flexbox-row--centered();
    justify-content: center;
}

@mixin flexbox-row--space-between() {
    @include flexbox-row();
    justify-content: space-between;
}

@mixin flexbox-row--space-between-centered() {
    @include flexbox-row--space-between();
    align-items: center;
}

@mixin flexbox-row--flex-start-centered() {
    @include flexbox-row();
    justify-content: flex-start;
    align-items: center;
}

@mixin flexbox-row--space-around-wrap() {
    @include flexbox-row();
    justify-content: space-around;
    flex-wrap: wrap;
}

@mixin flexbox-row--space-around-centered() {
    @include flexbox-row();
    justify-content: space-around;
    align-items: center;
}

@mixin flexbox-row--space-evenly-wrap() {
    @include flexbox-row();
    flex-wrap: wrap;
    justify-content: space-evenly;
}

/* #endregion */

/* #region flex row reverse */

@mixin flexbox-row-reverse() {
    display: flex;
    flex-direction: row-reverse;
}

@mixin flexbox-row-reverse--space-between() {
    @include flexbox-row-reverse();
    justify-content: space-between;
}

/* #endregion */

/* #region flex column */

@mixin flexbox-column() {
    display: flex;
    flex-direction: column;
}

@mixin flexbox-column--center() {
    @include flexbox-column();
    justify-content: center;
}

@mixin flexbox-column--centered() {
    @include flexbox-column();
    align-items: center;
}

@mixin flexbox-column--start() {
    @include flexbox-column();
    align-items: flex-start;
}

/* #endregion */

/* #region flex column reverse */

@mixin flexbox-column-reverse() {
    display: flex;
    flex-direction: column-reverse;
}

/* #endregion */

/* #region classes */

.flex-column--centered {
    @include flexbox-column--centered();
}

.flex-column--start {
    @include flexbox-column--start();
}

.flex-row--centered {
    @include flexbox-row--centered();
}

.flex-row--last-baseline {
    @include flexbox-row--last-baseline();
}

.flex-row--center {
    @include flexbox-row--center();
}

.flexbox-row--center-centered {
    @include flexbox-row--center-centered();
}

.flex-row--space-between {
    @include flexbox-row--space-between();
}

.flex-row-reverse--space-between {
    @include flexbox-row-reverse--space-between();
}

.flexbox-column {
    @include flexbox-column();
}

.flexbox-column-reverse {
    @include flexbox-column-reverse();
}

.flexbox-row--start-centered {
    @include flexbox-row--flex-start-centered();
}

.flex-row--space-between-centered {
    @include flexbox-row--space-between-centered();
}

.flexbox-row--space-around-centered {
    @include flexbox-row--space-around-centered();
}

.flex-row--space-around-wrap {
    @include flexbox-row--space-around-wrap();
}

.flex-area {
    flex-direction: row;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

/* below are the updated ones */

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-space-between-align-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.flex-align-center{
  display: flex;
  align-items: center;
}

.flex-column-align-center{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column-centered{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-column-align-start{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* #endregion */
