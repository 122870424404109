
.default-search-bar {
    position: relative;
    display: flex;

    >input {
        height: 38px;
        padding: 0 40px;
        border: 1px solid var(--border-color);
        border-radius: 12px;
        font-size: 14px;
        flex: 1;
        outline: none;
        transition: border-color 100ms linear;
        display: flex;
        align-items: center;
        background: none;
        color: var(--text-high);

        &:hover {
            border-color: var(--text-low);
        }

        &:focus {
            border-color: var(--accent-500);
        }
    }

    >mat-icon {
        color: var(--text-low);
        position: absolute;
        top: 4px;
        left: 8px;
        padding: 4px;

        &[svgicon='hailer-x'] {
            cursor: pointer;
            border-radius: 50%;
            left: unset;
            right: 4px;

            &:hover {
                background-color: var(--hover-background);
            }
        }
    }
}


/*#region reactions */
.reactions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    > button {
        height: $icon-size;
        min-width: $icon-size;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
}

.message-context-menu,
.mat-menu-content{
  .reactions{
    margin: 0 8px;
    padding: 4px;
  }
}

.actions-container {

    &.own-message {
        .reactions > button,
        .star-message {
            background-color: var(--reaction-container);

            &:focus-visible {
                outline: $focus-outline-light;
                z-index: 1;
            }
        }
    }

    &.user-message {
        .reactions > button,
        .star-message {
            background-color: var(--user-reaction-container);

            &:focus-visible {
                outline: $focus-outline;
                z-index: 1;
            }
        }
    }

    .reactions > button,
    .star-message {
        height: $icon-size;
        min-width: 35px;
        display: flex;
        align-items: center;
        padding: 4px 2px;
        box-sizing: border-box;
        border-radius: $rounded-edges-border-radius;
    }

    .reactions {

      @extend .reactions-emoji-text;

      > button:hover {
        transform: scale(1.05);
      }
    }

    .star-message {
        justify-content: center;
        justify-self: flex-end;

        mat-icon{
          width: 20px;
          height: 20px;
        }
    }

}
/* #endregion */
