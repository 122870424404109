@import 'hailer-colors';

.form-prefix {
    color: var(--text-medium);
    margin-right: 8px;
    margin-left: 8px;
    font-size: 21px;
}

.form-prefix-red {
    @extend .form-prefix;
    color: mat-color($hailer-app-warn);
}

// default field state
.form-field {
    width: 90%;

    .mat-input-element {
        font-size: 0.875rem;
        margin-top: 0.5em;

        &::placeholder {
            color: var(--input-placeholder);
        }
    }

    .mat-form-field-infix {
        padding: 0.5em 0 0.5em 0;
    }

    .mat-form-field-suffix,
    .mat-form-field-prefix {
        top: -0.1em !important;
    }

    .mat-form-field-prefix {
        margin-right: 8px;
    }

    .mat-form-field-label {
        color: var(--text-high);
        font-weight: 700;
        font-size: 0.75rem;
        transform: translateY(-0.7em) !important;
    }

    .mat-form-field-outline-start {
        background-color: var(--input-default-bg) !important;
        border-radius: 8px 0 0 8px !important;
        border-width: 1px !important;
    }
      
    .mat-form-field-outline-gap {
        border-top-color: inherit !important;
        background-color: var(--input-default-bg) !important;
        border-width: 1px !important;
    }
      
    .mat-form-field-outline-end {
        background-color: var(--input-default-bg) !important;
        border-radius: 0 8px 8px 0 !important;
        border-width: 1px !important;
    }

    .mat-form-field-outline-thick {
        color: transparent;
    }

    .mat-form-field-outline {
        color: transparent;
    }

    .mat-form-field-subscript-wrapper {
        margin-top: 0.3em;
    }

    &.highlight-bg {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            background-color: var(--input-highlight-bg) !important;
        }

        .mat-input-element::placeholder {
            color: var(--input-placeholder-highlight-bg);
        }
    }

    &.highlight-bg-light-theme-only {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            background-color: var(--input-highlight-bg-light-theme-only) !important;
        }

        .mat-input-element::placeholder {
            color: var(--input-placeholder-highlight-bg);
        }
    }

    &.search-field {
        .mat-form-field-wrapper {
            padding: 0;
            margin: 0;
        }

        .mat-input-element {
            font-size: 1rem;
            margin-top: 0;
            height: 24px;
        }
    
        .mat-form-field-infix {
            padding: 0 0 0.5em;
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
            top: 0.25em !important;

            mat-icon {
                height: 24px !important;
                width: 24px !important;
            }
        }    
    }

    &.new-feed-post-field {
        .mat-form-field-wrapper {
            padding: 0;
            margin: 0;
        }
    
        .mat-input-element {
            font-size: 1rem;
            margin-top: 0;
            height: 24px;
        }
    
        .mat-form-field-infix {
            padding: 0.1em 0 0.8em 0;
        }

        .mat-form-field-label {
            color: transparent;
            transform: translateY(-20px) !important;
            opacity: 0;
        }
    }

    &.new-feed-post-field-open {     
        .mat-form-field-label {
            opacity: 1;
            transition: opacity 0.3s ease, transform 0.3s ease;
        }

        .mat-form-field-infix {
            transition: padding 0.1s ease;
        }
    }

    // 'public forms' is for forms on login pages (login, register, forgot password..) that have only one theme (dark)
    &.public-forms {
        .mat-form-field-wrapper {
            margin: 0.05em 0;
        }

        .mat-form-field-label {
            color: var(--label-public-pages) !important;
        }

        .mat-input-element {
            caret-color: var(--text-public-pages) !important;
            color: var(--text-public-pages) !important;

            &::placeholder {
                color: var(--placeholder-public-pages);
            }
        }

        .mat-form-field-suffix {
            color: var(--label-public-pages) !important;

            &:hover {
                color: var(--text-public-pages) !important;
            }
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            background-color: var(--input-default-bg-public-pages) !important;
            color: var(--input-border-default-public-pages) !important;
        }
    }

    &.full-width {
        width: 100%;
    }
}

// focused field state
.form-field.mat-focused {
    .mat-input-element {
        caret-color: var(--text-high);
    }

    .mat-form-field-label {
        color: var(--text-high) !important;
    }
    
    .mat-form-field-required-marker {
        color: var(--text-high) !important;
    }

     // 'public forms' is for forms on login pages (login, register, forgot password..) that have only one theme (light)
     &.public-forms {
        .mat-input-element {
            caret-color: var(--text-public-pages) !important;
            color: var(--text-public-pages) !important;

            &::placeholder {
                color: var(--placeholder-public-pages);
            }
        }

        .mat-form-field-label {
            color: var(--text-public-pages) !important;
        }
        
        .mat-form-field-required-marker {
            color: var(--text-public-pages) !important;
        }

        .mat-form-field-suffix {
            color: var(--label-public-pages) !important;

            &:hover {
                color: var(--text-public-pages) !important;
            }
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            color: var(--input-border-active-public-pages) !important;
        }
    }
}

// invalid field state
.form-field.mat-form-field-invalid {
    .mat-form-field-label {
        color: var(--text-high) !important;
        
        .mat-form-field-required-marker {
            color: var(--text-high);
        }
    }

    .mat-input-element {
        caret-color: var(--text-high);
    }

    .mat-form-field-outline-start {
        background-color: var(--input-error-background) !important;
        border-radius: 8px 0 0 8px !important;
    }
      
    .mat-form-field-outline-gap {
        background-color: var(--input-error-background) !important;
    }
      
    .mat-form-field-outline-end {
        background-color: var(--input-error-background) !important;
        border-radius: 0 8px 8px 0 !important;
    }

    // 'public forms' is for forms on login pages (login, register, forgot password..) that have only one theme (dark)
    &.public-forms {
        .mat-form-field-label {
            color: var(--text-public-pages) !important;
            
            .mat-form-field-required-marker {
                color: var(--text-public-pages) !important;
            }
        }

        .mat-input-element {
            caret-color: var(--text-public-pages) !important;
            color: var(--text-public-pages) !important;

            &::placeholder {
                color: var(--placeholder-public-pages);
            }
        }

        .mat-form-field-outline-start {
            background-color: var(--input-error-background-public-pages) !important;
        }
          
        .mat-form-field-outline-gap {
            background-color: var(--input-error-background-public-pages) !important;
        }
          
        .mat-form-field-outline-end {
            background-color: var(--input-error-background-public-pages) !important;
        }

        .mat-form-field-suffix {
            color: var(--label-public-pages) !important;

            &:hover {
                color: var(--text-public-pages) !important;
            }
        }

        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end  {
            color: var(--warn-public-pages) !important;            
        }

        .mat-error, .red-hint {
            color: var(--warn-text-public-pages) !important;
        }
    }

    &.default-email-field {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end  {
            color: var(--input-border-default-public-pages) !important;           
        }

        .mat-form-field-label {
            color: var(--label-public-pages) !important;

            .mat-form-field-required-marker {
                color: var(--label-public-pages) !important;
            }
        }
    }
}