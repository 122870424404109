/** @format */
@use 'variables' as variables;

.grid-container-input-with-clear {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: variables.$element-gap;
    width: 100%;

    &.text-area {
        align-items: start;
    }

    mat-form-field,
    .mat-form-field {
        width: -webkit-fill-available;
        width: -moz-available;
    }

    .field-view {
        width: 100%;
    }
}
