
.fc-timeline-event {
    white-space: pre-wrap !important;
    padding: 0 2px;
    max-height: 58px;
    text-overflow: ellipsis;
}

.fc-event-title {
    white-space: pre-wrap !important;
    padding: 0 2px;
    max-height: 38px;
    text-overflow: ellipsis;
}

.fc-datagrid-cell-cushion {
    white-space: pre-wrap !important;
    max-height: 79px !important;
    text-overflow: ellipsis;
}

.fc-datagrid-cell-main {
    white-space: pre-wrap !important;
    max-height: 79px !important;
    text-overflow: ellipsis;
}

@media screen and (max-width: 600px) {
    .fc-daygrid-week-number {
        bottom: 0 !important;
        top: auto !important;
    }
}
