@import 'flexbox';


$only-button-height: 24px;
$only-button-width: 52px;

/* #region mixins [have to be at the top!] */

@mixin only-button {

    .only-button {
        visibility: hidden;
        height: $only-button-height;
        width: $only-button-width;
        color: var(--accent-500);
        opacity: 0 !important;
        transition: all 0.2s ease !important;
    }

    .mat-checkbox-layout {
        white-space: pre-line;
    }
}

@mixin show-only-button {

    .only-button {
        height: $only-button-height;
        width: $only-button-width;
        color: var(--accent-500);
        visibility: visible;
        opacity: 1 !important;
        background-color: var(--background-primary);
    }
}

@mixin selection-list-text() {
    display: grid;
    grid-template-columns: 2fr 0.5fr;
    grid-gap: 5px;
    align-items: center;
}

@mixin only-holder() {
    transition: all 0.2s ease !important;

    @include flexbox-row();

    mat-option {
        width: calc(100% + 128px);
    }

    @include only-button();

    .only-holder-text {
        @include selection-list-text();

        p {
            margin: 0;
            padding: 0;
            font-size: 14px;
        }
    }
}

@mixin only-holder-hover () {

    background: var(--filter-hover);

    button.mat-stroked-button,
    button.mat-flat-button,
    button.mat-button,
    button.mat-raised-button,
    mat-chip.mat-standard-chip,
    button.mat-button-toggle-button {
        border-radius: 0px;

        >span.mat-button-wrapper {
            @include flexbox-row--center-centered();
        }
    }

    mat-option {
        width: 100%;
    }

    @include show-only-button();

}


@mixin topbar-filter-btn() {
    color: var(--filter-active-text);
    background-color: var(--activity-filter-hover);
    border-radius: 8px !important;
    text-transform: none !important;
    font-size: 0.875rem !important;
    font-weight: 700 !important;

    mat-icon {
        margin-right: 8px;
        position: relative;

        @media only screen and (max-width: 1350px) {
            margin-right: 2px;
        }
    }

    mat-icon[svgicon="hailer-x"] {
        margin-right: 0;
        margin-left: 8px;

        @media only screen and (max-width: 500px) {
            margin-left: 2px;
        }
    }
}

@mixin topbar-filter-btn-invert-icon() {
    content: '';
    background: var(--bubble-bg);
    height: 10px;
    width: 10px;
    position: absolute;
    border-radius: 50%;
    right: 0;
    top: 0;
}

/* #endregion */

/* #region variables */

$input-padding: 8px 16px 8px 8px;
/* #endregion */

/* #region topbar filter buttons */

/* #endregion */

/* #region activity filters */

.select-all {
    padding: $input-padding;
    margin: 0;
    border-bottom: 1px solid var(--border-color);
    font-size: 16px;

    &:hover {
        background-color: var(--activity-filter-hover);
    }
}

.select-all-activity-filter {
    @extend .select-all;
    padding: 8px 16px 8px 4px;
    font-size: 14px !important;
    min-height: 24px;
    height: auto;

    .mat-checkbox{
        margin: 0;
    }
}

.options-filter-all {
    border-bottom: 1px solid var(--border-color);
}

.search-for-activity-filter {
    margin-top: 8px;
    margin-bottom: 8px;

    .activity-filter {
        width: 100%;
    }

    mat-form-field.mat-form-field.mat-form-field-appearance-fill {
        .mat-form-field-infix {
            display: flex;
        }

        .mat-form-field-wrapper {
           border-radius: 10px !important;

            .mat-form-field-flex {
                border-radius: 10px !important;
            }
        }
    }
}

/* TODO check height */
.filter-options {
    width: 100%;
    overflow-x: hidden;
}

.filter-options-scroll {
    max-height: 300px;
    overflow-y: scroll;
}

.filter-option {
    padding: $input-padding;
}

.activity-field-header {
    display: grid;
    grid-template-columns: auto 1fr auto 1fr;
    justify-items: start;
    align-items: center;
}

.filter-field {
    @include flexbox-row--space-between-centered();

    padding: 3px 8px;
}

/* #endregion */

/* #region buttons */

.clearButton {
    right: 8px;
}

.only-holder {
    @include only-holder();
}

.only-holder:hover {
    @include only-holder-hover()
}

.activity-field-filter-no-button {
    @include flexbox-row--centered();
    padding: $input-padding;

    span {
        margin-left: 15px;
    }
}

/* #endregion */

/* #region checkboxes */

@mixin activity-filter-checkbox {

    mat-checkbox,
    .mat-checkbox {
        margin: 2.2% 8% 2%;
    }
}

/* #endregion */

/* #region icons */

.expansion-arrow-container {
    padding: 8px;
    width: 8px;
    height: 8px;
    line-height: 0;
}

/* #endregion */


