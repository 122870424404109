@import 'variables';
/* #region mixins */

/* sets the background colour for circle icons */

@mixin circle-background($backgroundColor: var(--background-primary), $iconColor: inherit, $border: none, $size: $icon-size) {
  background-color: $backgroundColor;
  color: $iconColor;
  border-radius: 50%;
  border: $border;
  height: $size;
  width: $size;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* #endregion */

.tooltip-icon {
  color: var(--grey-400);
  cursor: default;
  height: 21px;
  width: 21px;
  font-size: 21px;
  margin: 0 6px;

  &-small {
    color: var(--grey-400);
    height: 18px !important;
    width: 18px !important;
    font-size: 18px;
    margin: 0 4px;
    cursor: default;
  }
}

.hailer-chevron {
  color: inherit;
  flex-shrink: 0;

  &.left {
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(-90deg);
  }

  &.up {
    transform: rotate(180deg);
  }
}

.back-arrow {
  flex-shrink: 0;
  transform: rotate(-90deg);
}

.forward-arrow {
  flex-shrink: 0;
  transform: rotate(90deg);
}

mat-icon {
  height: $icon-size;
  width: $icon-size;

  &.success{
    color: var(--icon-success) !important;
  }

  &.warn,
  &.warning {
    color: var(--icon-destructive) !important;
    }

  &.accent {
    color: var(--accent-500) !important;
  }

  &.primary {
    color: var(--primary-500) !important;
  }

  &.white {
    color: var(--white) !important;
  }
  &.small{
    height: $icon-size-small;
    width: $icon-size-small;
  }

  &.default {
    color: inherit !important; 
  }

  &.icon-default {
    color: var(--icon-default);
  }

  &.dimmed-prefix {
    color: var(--input-placeholder);
  }
}

.warning-icon {
    width: 17px !important;
    height: 17px !important; 
    flex-shrink: 0;
    margin: 8px;
    color: var(--icon-destructive);
}

.mat-menu-item .mat-icon {
    color: var(--icon-default);
}

.arrow-circle {
  @include circle-background($iconColor: var(--accent-100));
}

.chevron-circle {
  @include circle-background($backgroundColor: var(--grey-400));
}

.star-icon,
.starred-icon {
    overflow: visible;
    flex-shrink: 0;

    &.active {
        color: var(--starred-primary) !important;
    }

    &.inactive {
        color: inherit;
    }
}

.starred-icon {
    color: var(--starred-primary);
    height: 18px;
    width: 18px;
}

/* #region old classes */
.figma-icons-line-white {
  fill: none;
  stroke-width: 1.5;
  stroke: rgba(230, 230, 230, 0.87);
  stroke-linecap: round;
  stroke-linejoin: round;
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.figma-icons-fill-white {
  color: var(--icon-color);
  fill: var(--icon-color);
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.figma-icons-fill-grey {
  color: var(--grey-400);
  fill: var(--grey-400);
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.figma-icons-fill-green {
  color: var(--accent-500);
  fill: var(--accent-500);
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.figma-icons-fill-blue {
  color: var(--blue-700);
  fill: var(--blue-700);
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.figma-icons-line {
  fill: none;
  stroke-width: 1.5;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.figma-icons-fill {
  color: var(--primary-500);
  fill: var(--primary-500);
  fill-rule: evenodd;
  clip-rule: evenodd;
}

/* #endregion */
