@import 'variables';
@import 'flexbox';

.bubble {
    color: var(--bubble-text);
    background: var(--bubble-bg);
    border-style: solid;
    border-width: 2px;
    border-radius: 15px;
    border-color: var(--bubble-bg);
    margin: 0 8px;
    flex-shrink: 0;

    &.unread {
        background: var(--warn-500);
        border-color: var(--warn-500);
    }

    @include flexbox-row--center-centered();
}

.enabled-bubble {
    width: 5px;
    height: 5px;

    @extend .bubble;
}

.amount-bubble {
    font-weight: 600;
    min-width: 14px;
    max-width: 40px;
    height: 14px;

    @extend .bubble;

    p {
        font-size: 0.75rem;
        padding: 3px !important;
    }
}
