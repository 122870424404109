@import 'src/styles/components/_buttons.scss';

html,
body {
    // overscroll-behavior-y: contain; // Should disable pull-down-to-refresh on PWA and newer chrome versions
}

body {
    height: 100vh;
    margin: 0;
    background-color: var(--background);
}


code {
    * {
        font-family: monospace;
    }
}

a[href] {
    color: var(--links-default);

    &:link,
    &:visited {
        text-decoration: none;
    }

    &:hover,
    &:active {
        text-decoration: underline;
    }

    &:focus-visible {
        outline: $focus-outline;
        border-radius: $focus-border-radius-small;
    }
}