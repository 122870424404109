@import '@angular/material/theming';
@import 'hailer-palette';

$custom-typography: mat-typography-config(
    $font-family: 'Nunito Sans, sans-serif, emoji',
);

@include mat-core($custom-typography);

$hailer-app-primary: mat-palette($mat-hailer-blue);
$hailer-app-accent: mat-palette($mat-hailer-green);
$hailer-app-warn: mat-palette($mat-hailer-red);

$hailer-app-theme: mat-light-theme($hailer-app-primary, $hailer-app-accent, $hailer-app-warn);

@include angular-material-theme($hailer-app-theme);

$dark-theme-background: (
    status-bar: black,
    app-bar:    adjust-color(map_get($mat-grey, 900), $hue: 214, $saturation: 10%, $lightness: -3%),
    background: adjust-color(map_get($mat-grey, 900), $hue: 214, $saturation: 10%, $lightness: -3%),
    hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
    card:       adjust-color(map_get($mat-grey, 800), $hue: 214, $saturation: 10%, $lightness: -3%),
    dialog:     adjust-color(map_get($mat-grey, 800), $hue: 214, $saturation: 10%, $lightness: -3%),
    disabled-button: rgba(white, 0.12),
    raised-button: adjust-color(map_get($mat-grey, 800), $hue: 214, $saturation: 10%, $lightness: -3%),
    focused-button: $light-focused,
    selected-button: adjust-color(map_get($mat-grey, 900), $hue: 214, $saturation: 10%, $lightness: -3%),
    selected-disabled-button: adjust-color(map_get($mat-grey, 800), $hue: 214, $saturation: 10%, $lightness: -3%),
    disabled-button-toggle: black,
    unselected-chip: adjust-color(map_get($mat-grey, 700), $hue: 214, $saturation: 10%, $lightness: -3%),
    disabled-list-option: black,
);

$dark-theme-foreground: (
    base:              rgba(white, 0.87),
    divider:           $light-dividers,
    dividers:          $light-dividers,
    disabled:          $light-disabled-text,
    disabled-button:   rgba(white, 0.3),
    disabled-text:     $light-disabled-text,
    elevation:         black,
    hint-text:         $light-disabled-text,
    secondary-text:    $light-secondary-text,
    icon:              rgba(white, 0.87),
    icons:             rgba(white, 0.87),
    text:              rgba(white, 0.87),
    slider-min:        rgba(white, 0.87),
    slider-off:        rgba(white, 0.3),
    slider-off-active: rgba(white, 0.3),
);

@function create-dark-theme($primary, $accent, $warn) {
    @return (
        primary: $primary,
        accent: $accent,
        warn: $warn,
        is-dark: false,
        foreground: $dark-theme-foreground,
        background: $dark-theme-background,
    );
}


.hailer-app-dark-theme {
    $hailer-app-dark-primary: mat-palette($mat-hailer-dark-blue);
    $hailer-app-dark-accent: mat-palette($mat-hailer-dark-green);
    $hailer-app-dark-warn: mat-palette($mat-hailer-dark-red);
    $hailer-app-dark-theme: create-dark-theme($hailer-app-dark-primary, $hailer-app-accent, $hailer-app-dark-warn);

    @include angular-material-theme($hailer-app-dark-theme);
}

// Importing styles
@import 'user-input';

/*
Comment out everything else and enable this to fix the scss problem, but it removes all styles!

$hailer-app-primary: #3a52db;
$hailer-app-accent:  rgb(69, 224, 55);
$hailer-app-warn: rgb(211, 59, 59);
*/
