@use 'variables';

.doc-template-container {
    border: $divider-default-border;
    border-radius: 8px;
    padding: 16px;
    background-color: var(--background-secondary);
    position: relative;
}

.info-text {
    text-align: left;
    font-size: 13px;
    padding-left: 0px;
    padding-top: 0px;
    font-style: italic;
}

.row-field-negative {
    margin-top: -12px;
}

.adv-divider {
    cursor: pointer;
    text-align: center;
}

.advanced-container {
    height: 0;
    transition: height 200ms ease-in-out;
    overflow: hidden;
    position: relative;
}

.advanced-container-open {
    height: 80px;
}

@media only screen and (max-width: 600px) {
    .advanced-container-open {
        height: 80px;
    }
}

.link-selector {
    cursor: pointer;
    color: var(--accent-500);
}
