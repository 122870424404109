.dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--container-background);

    height: 56px;
    padding: 15px 20px; 
    box-sizing: border-box;
    margin: -24px -24px 0 -24px;

    h1 {
        margin: 0 !important;
        font-size: 20px;
        font-weight: 500;
    }
}