/** @format*/

/* #region font variables */

/* #region font sizes */
$h1: 1.125rem;
$h2: 1rem;
$h3: 0.875rem;
$h4: 0.8rem;
$h5: 0.75rem;
// Note: should not have font sizes below 12px (0.75rem) in texts for accessibility reasons
$h6: 0.7rem; 
$h7: 0.65rem;
/* #endregion */

/* #region font weights */
$extrabold: 800;
$bold: 700;
$semibold: 600;
$regular: 400;
$light: 300;

/* #endregion */

/* #region line height */
$normal-line-height: normal;
$small-line-height: 0.95em;
$tiny-line-height: 0.75em;
$large-line-height: 1.125em;
$extralarge-line-height: 1.25em;

/* #endregion */

/* #endregion */

/* #region colors */

.text-low {
	color: var(--text-low);
}

.text-med,
.text-medium {
	color: var(--text-medium);
}

.text-high {
	color: var(--text-high);
}

/* #endregion */

/* #region classes */

.one-line-ellipsis {
	white-space: break-spaces;
	text-overflow: ellipsis;
	overflow: hidden;
	word-wrap: break-word;

	@supports (-webkit-line-clamp: 1) {
		-webkit-line-clamp: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		word-break: break-all;
	}
}

.two-line-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: normal;
    overflow-wrap: anywhere;
    hyphens: auto;

    @supports (-webkit-line-clamp: 2) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -webkit-hyphens: auto;
    }
}

.three-line-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: normal;
    overflow-wrap: anywhere;
    hyphens: auto;

    @supports (-webkit-line-clamp: 3) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        -webkit-hyphens: auto;
    }
}

.bold {
	font-weight: $bold;
}

.semibold {
	font-weight: $semibold;
}

.input-hints {
	font-size: 0.6rem;
	font-weight: 300;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: inherit;
}

.text-align-left {
    text-align: left !important;
}

.text-align-center {
    text-align: center !important;
}

/* #endregion */

/* #region main fonts */
//  NS Title Bold 1
.ns-title-bold-1 {
	font-weight: $bold;
	font-size: $h1;
	line-height: $extralarge-line-height;
}

//  NS Title Bold 2
.ns-title-bold-2 {
	font-weight: $bold;
	font-size: $h2;
	line-height: $large-line-height;
}

// NS title Bold 3
.ns-title-bold-3 {
	font-weight: $bold;
	font-size: $h3;
	line-height: $normal-line-height;
}

// NS title semibold 1
.ns-title-semibold-1 {
	font-weight: $semibold;
	font-size: $h1;
	line-height: $large-line-height;
}

// NS Headline Extra bold 1
.ns-headline-extrabold-1 {
	font-weight: $extrabold;
	font-size: $h3;
	line-height: $normal-line-height;
}

// NS Headline Bold 1
.ns-headline-bold-1 {
	font-weight: $bold;
	font-size: $h4;
	line-height: $normal-line-height;
}

// NS Headline Bold 2
.ns-headline-bold-2 {
	font-weight: $bold;
	font-size: $h5;
	line-height: $normal-line-height;
}

// NS Headline Semibold 1
.ns-headline-semibold-1 {
	font-weight: $semibold;
	font-size: $h2;
	line-height: $normal-line-height;
}

// NS Headline Semibold 2
.ns-headline-semibold-2 {
	font-weight: $semibold;
	font-size: $h3;
	line-height: $normal-line-height;
}

// NS Headline Semibold 3
.ns-headline-semibold-3 {
	font-weight: $semibold;
	font-size: $h4;
	line-height: $normal-line-height;
}

// NS Headline Semibold 4
.ns-headline-semibold-4 {
	font-weight: $semibold;
	font-size: $h5;
	line-height: $normal-line-height;
}

// NS Text Regular 1
.ns-text-regular-1 {
	font-weight: $regular;
	font-size: $h3;
	line-height: $large-line-height;
}

// NS Text Regular 2
.ns-text-regular-2 {
	font-weight: $regular;
	font-size: $h4;
	line-height: $normal-line-height;
}

// NS Text Regular 3
.ns-text-regular-3 {
	font-weight: $regular;
	font-size: $h5;
	line-height: $normal-line-height;
}

// NS Text Regular 4
.ns-text-regular-4 {
	font-weight: $regular;
	font-size: $h2;
	line-height: $large-line-height;
}

// NS Text Regular 5
.ns-text-regular-5 {
	font-weight: $regular;
	font-size: $h6;
	line-height: $small-line-height;
}

// NS Text Light 1
.ns-text-light-1 {
	font-weight: $light;
	font-size: $h2;
	line-height: $extralarge-line-height;
}

// NS Text Light 2
.ns-text-light-2 {
	font-weight: $light;
	font-size: $h3;
	line-height: $normal-line-height;
}

// NS Text Light 3
.ns-text-light-3 {
	font-weight: $light;
	font-size: $h4;
	line-height: $small-line-height;
}

// NS Text Small 1 / NS Caption Regular 1
.ns-text-small-1 {
	font-weight: $regular;
	font-size: $h6;
	line-height: $normal-line-height;
}

// NS Text Small 2 / NS Caption Regular 2
.ns-text-small-2 {
	font-weight: $regular;
	font-size: $h7;
	line-height: $normal-line-height;
}

// NS Text Small 3 / NS Caption Regular 3
.ns-text-small-3 {
	font-weight: $regular;
	font-size: $h6;
	line-height: $normal-line-height;
}

// NS Extra bold small 1
.ns-extrabold-small-1 {
	font-weight: $extrabold;
	font-size: $h5;
	line-height: $normal-line-height;
}

// NS Extra bold small 2
.ns-extrabold-small-2 {
	font-weight: $extrabold;
	font-size: $h6;
	line-height: $normal-line-height;
}

/* #endregion */

/* #region button font */

//NS Button Bold
.ns-button-bold {
	font-weight: $bold;
	font-size: $h5;
	line-height: 1.36em;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.ns-button-bold-1 {
	font-weight: $extrabold;
	font-size: $h5;
	line-height: $normal-line-height;
}

.button-font {
	font-weight: $semibold;
	font-size: $h5;
	line-height: $normal-line-height;
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

/* #endregion */

/* #region specialized fonts */

.panel-header-title {
	font-weight: $bold;
	font-size: $h3;
	line-height: $normal-line-height;
}

.timestamp {
  white-space: nowrap;
  padding-left: 4px; 
  color: var(--small-text-gray);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: $normal-line-height;
}

.timestamp-darker {
    @extend .timestamp;
    color: var(--small-text-darker-gray);
}

.error-field {
	font-weight: $regular;
	font-size: $h5;
	line-height: $tiny-line-height;
	color: var(--warn-500);
}

.input-hints {
	font-size: 0.6rem;
	font-weight: 300;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: inherit;
}
.field-view {
	font-weight: $regular;
	font-size: $h3;
	line-height: $large-line-height;
}

.subheader {
	font-weight: $semibold;
	font-size: $h6;
	line-height: 1.5em;
}

.field-label {
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: $bold;
	font-size: $h5;
	line-height: $normal-line-height;
}

.dialog-main-header {
	font-weight: $bold;
	font-size: 1.4rem;
	line-height: 1.2em;
	margin: 0;
}

.dialog-second-header {
	font-weight: $semibold;
	font-size: 1.5rem;
	line-height: 1.625rem;
	text-align: center;
}

.dialog-body-text {
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
    font-weight: 300;
}

.reactions-emoji-text {
	font-weight: $regular;
	font-size: 0.9rem;
	line-height: 1.5em;
}

/* #endregion */
