@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/animations/scale.css';

.tippy-box {
    background: var(--background-primary) !important;
    color: var(--text-high) !important;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    .tippy-arrow {
        color: var(--background-primary) !important;
    }
}