@import 'variables';
@import '../hailer-colors';


@mixin ripple-hover($color: var(--accent-500),
  $background-color: inherit,
  $border-radius: $rounded-edges-border-radius,
  $invert-colors: false,
  $make-darker: false,
  $make-lighter: false) {
  &:hover:not([aria-disabled="true"], :disabled), &:focus:not([aria-disabled="true"]) {
    color: $color;
    background-color: $background-color;
    border-radius: $border-radius;

    @if $invert-colors {
      filter: invert(0.95);
    }

    @if $make-darker {
      filter: brightness(0.95);
    }

    @if $make-lighter {
      filter: brightness(1.5);
    }
  }
}

