@import 'hailer-colors';

/* #region matMenu with checkboxes */

.checkbox-menu {
    .mat-checkbox-inner-container {
       margin: 0 0 2px 0;
    }

    mat-divider {
        margin: 0 12px;
    }

    mat-checkbox {
        pointer-events: none;
        margin: 0 10px 0 0;
    }
}

/* #endregion matMenu with checkboxes */

.destructive-text {
    color: var(--warn-text) !important;
}
