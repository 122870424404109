/* #region imports */
/* reset and normalization */

@import 'styles/global/base/reset';
@import 'styles/global/base/typography';
@import 'flexbox';
@import 'styles/variables.scss';
@import 'animations';
@import 'grid';


/* vendor styles */

@import 'styles/vendors/tippy/tippy';
@import 'styles/vendors/flags/flags';
@import 'styles/vendors/mat/mat';
@import 'styles/vendors/full-calendar/fc';

/* themes */

@import 'styles/hailer-theme.scss';

/* components */

@import 'styles/components/bubbles';
@import 'styles/components/buttons';
@import 'styles/components/fabulous-salmon';
@import 'styles/components/icons';
@import 'styles/components/dialogs';
@import 'styles/components/inputs';
@import 'styles/components/popups';
@import 'styles/components/tooltips';
@import 'styles/components/menus';

/* modules */

@import 'styles/global/forms.scss';
@import 'styles/picker.min.css';
@import 'styles/global/activity-filters';
@import 'styles/global/process-editor';
@import 'styles/modules/events-module';

/* #endregion */


// Testing SCSS

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,400,400i,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Nunito:200,400,600,700,800');

// Quill themes
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

$font-nunito: 'Nunito Sans',
    sans-serif, emoji;


* {
    font-family: $font-nunito;
}

/* Targeting Firefox only: */
@-moz-document url-prefix() {
    * {
        scrollbar-width: thin;
    }
}

/* You can add global styles to this file, and also import other style files */

img.emoji {
    height: 1.15em;
    width: 1.15em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

.tippy-box {
    background: var(--background-primary) !important;
    color: var(--text-high) !important;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    word-wrap: break-word;
    word-break: normal;

    .tippy-arrow {
        color: var(--background-primary) !important;
    }
}

.display-complete-tooltip {
    word-break: normal !important;
    white-space: normal !important;
}

.calendar-title {
    cursor: pointer;
}

.message-context-menu,
.discussion-context-menu {
    background-color: var(--background-primary);
    font-size: 16px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 8px 0;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader {
    margin: 60px auto;
    font-size: 4px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid;
    border-right: 1.1em solid;
    border-bottom: 1.1em solid;
    border-left: 1.1em solid;
    border-color: var(--hover-background);
    border-left-color: var(--primary-A400);
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (min-width: 768px) {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        min-height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        height: 6px;
        min-height: 50px;
        border: 1px solid transparent;
        background-clip: padding-box;
        background-color: rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.4);
    }

    ::-webkit-scrollbar-thumb:active {
        background-color: rgba(0, 0, 0, 0.6);
    }

    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}


/* fix naming */

.hlr-public-network-list-item-icon {
    background: #2980b9;
    /* fallback for non-supporting browsers */
    background: -webkit-gradient(radial, center center, 0, center center, 141, from(#3498db), to(#2980b9), color-stop(25%, #2980b9));
    /* old WebKit Syntax */
    background: -webkit-radial-gradient(#3498db, #2980b9);
    /* Safari 5.1- 6.0 */
    background: -o-radial-gradient(#3498db, #2980b9);
    /* Opera 11.6-12.0 */
    background: -moz-radial-gradient(#3498db, #2980b9);
    /* Firefox 3.6-15 */
    background: radial-gradient(#3498db, #2980b9);
    /* Standard syntax */
}

.hlr-invitation-list-item-icon {
    background: #16a085;
    /* fallback for non-supporting browsers */
    background: -webkit-gradient(radial, center center, 0, center center, 141, from(#1abc9c), to(#16a085), color-stop(25%, #16a085));
    /* old WebKit Syntax */
    background: -webkit-radial-gradient(#1abc9c, #16a085);
    /* Safari 5.1- 6.0 */
    background: -o-radial-gradient(#1abc9c, #16a085);
    /* Opera 11.6-12.0 */
    background: -moz-radial-gradient(#1abc9c, #16a085);
    /* Firefox 3.6-15 */
    background: radial-gradient(#1abc9c, #16a085);
    /* Standard syntax */
}

.hlr-msgr-activity-icon {
    background: #27ae60;
    /* fallback for non-supporting browsers */
    background: -webkit-gradient(radial, center center, 0, center center, 141, from(#2ecc71), to(#27ae60), color-stop(25%, #27ae60));
    /* old WebKit Syntax */
    background: -webkit-radial-gradient(#2ecc71, #27ae60);
    /* Safari 5.1- 6.0 */
    background: -o-radial-gradient(#2ecc71, #27ae60);
    /* Opera 11.6-12.0 */
    background: -moz-radial-gradient(#2ecc71, #27ae60);
    /* Firefox 3.6-15 */
    background: radial-gradient(#2ecc71, #27ae60);
    /* Standard syntax */
}

.hlr-msgr-event-icon {
    background: #c0392b;
    /* fallback for non-supporting browsers */
    background: -webkit-gradient(radial, center center, 0, center center, 141, from(#e74c3c), to(#c0392b), color-stop(25%, #c0392b));
    /* old WebKit Syntax */
    background: -webkit-radial-gradient(#e74c3c, #c0392b);
    /* Safari 5.1- 6.0 */
    background: -o-radial-gradient(#e74c3c, #c0392b);
    /* Opera 11.6-12.0 */
    background: -moz-radial-gradient(#e74c3c, #c0392b);
    /* Firefox 3.6-15 */
    background: radial-gradient(#e74c3c, #c0392b);
    /* Standard syntax */
}

.hlr-msgr-group-icon {
    background: #2980b9;
    /* fallback for non-supporting browsers */
    background: -webkit-gradient(radial, center center, 0, center center, 141, from(#3498db), to(#2980b9), color-stop(25%, #2980b9));
    /* old WebKit Syntax */
    background: -webkit-radial-gradient(#3498db, #2980b9);
    /* Safari 5.1- 6.0 */
    background: -o-radial-gradient(#3498db, #2980b9);
    /* Opera 11.6-12.0 */
    background: -moz-radial-gradient(#3498db, #2980b9);
    /* Firefox 3.6-15 */
    background: radial-gradient(#3498db, #2980b9);
    /* Standard syntax */
}

.hlr-msgr-tasklist-icon {
    background: #e67e22;
    /* fallback for non-supporting browsers */
    background: -webkit-gradient(radial, center center, 0, center center, 141, from(#f39c12), to(#e67e22), color-stop(25%, #e67e22));
    /* old WebKit Syntax */
    background: -webkit-radial-gradient(#f39c12, #e67e22);
    /* Safari 5.1- 6.0 */
    background: -o-radial-gradient(#f39c12, #e67e22);
    /* Opera 11.6-12.0 */
    background: -moz-radial-gradient(#f39c12, #e67e22);
    /* Firefox 3.6-15 */
    background: radial-gradient(#f39c12, #e67e22);
    /* Standard syntax */
}

.hlr-msgr-user-icon {
    border: 2px solid var(--border-color);
    float: left;
    height: 40px;
    margin-right: 10px;
    width: 40px;
}



.btn-picker {
    border-radius: 50%;
    margin-left: 15px;
}

div>.cdk-overlay-pane {
    max-width: 95vw !important;
    max-height: calc(var(--vh, 1vh) * 100 - 64px);
}

.img-circle {
    border-radius: 50%;
}

.mat-tooltip {
    font-size: 12px;
    border-radius: 8px !important;
    letter-spacing: .5px;
    background: #000e20;
    padding: 4px;
}

.mat-snack-bar-container {
    background: var(--container-background) !important;
    color: white !important;

    .mat-simple-snackbar-action button {
        color: #6affa1 !important;
    }
}

.popover-container {
    background: var(--background-primary);
    border-radius: 8px;
    padding: 4px;
    font-size: 14px;
    color: var(--text-high);
    max-width: 280px;
    text-align: center;

    a {
        color: #a4c1ff;

        &:link,
        &:visited {
            text-decoration: none;
        }

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.half-width {
    width: 50%;
}

.activities-card {
    padding-bottom: 100px;
    background-color: mat-color($hailer-app-primary, 50);
    width: 100%;
    //height: calc(100vh - 124px);
}

.container {
    @include flexbox-row;
}

.grid-container {
    &-small-first {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 0.5fr 4fr;
        justify-content: start;
    }

    &-big-first {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 2fr 0.5fr;
        justify-content: start;
    }
}

.center-icon-button {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}

//dialog box classes:

.borderless-dialog {
    .mat-dialog-container {
        padding: 0;
    }
}

.join-network-dialog {
    .mat-dialog- {
        border-radius: 8px;
        overflow-x: hidden;
        max-width: 548px;
    }
}

.dialog-padding {
    padding: 0 24px;
}

.activity-buttons {
    padding: 8px 16px;
    background-color: var(--background-primary);
    box-shadow: 0px -8px 19px -16px #0000003d;
    z-index: 1;

    button {
        border-radius: 20px;
    }
}

.margin-right-8px {
    margin-right: 8px;
}

.mat-column-select {
    overflow: initial;
}

.topbar {
    flex-direction: row-reverse;
    justify-content: space-between;
    box-sizing: border-box;
    height: $topbar-height;
    align-items: center;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 16px;
    right: 0;
    bottom: 0;
    display: flex;
}

.button-clear {
    display: flex !important;
    justify-content: center !important;
    position: absolute !important;
    top: 8px !important;
    right: 8px !important;
    background-color: rgba(0, 0, 0, 0.3) !important;

    mat-icon {
        color: #fff !important;
    }
}

.activity-topbar-icons {
    color: var(--text-medium);
    align-items: center;
    display: flex;
}


.disable-selection {
    -moz-user-select: none; // Firefox
    -ms-user-select: none; // Internet Explorer
    -webkit-user-select: none; // Chrome, Safari, and Opera
    -webkit-touch-callout: none; // Disable Android and iOS callouts
    user-select: none;
}

.sidebar {
    box-sizing: border-box;
    max-width: $sidebar-width;
}

.authenticatingAnimation {
    -webkit-animation: sk-bounce 1.5s infinite ease-in-out;
    animation: sk-bounce 1.5s infinite ease-in-out;
}

.fill-field-container {
    mat-form-field {
        .mat-form-field-flex {
            margin: 0;
            height: 34px;
            align-items: center;
            padding: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .mat-form-field-wrapper {
            padding: 0;
        }

        .mat-form-field-flex {
            margin: 0;
            padding: 0;
        }

        .mat-form-field-infix {
            border: 0;
            padding: 6px 0;
        }

        input,
        textarea {
            padding: 4px 8px;
            width: calc(100% - 16px);
        }

        .mat-form-field-underline {
            transform: translateY(20px);

            &:before {
                content: none;
            }
        }

        .mat-form-field-label {
            margin-left: 8px;
        }

        .mat-form-field-label-wrapper {
            height: 14px;
            top: 0px;
        }

        .mat-form-field-suffix {
            bottom: 4px;
            padding: 4px 8px 4px 0;
        }

        .mat-select-value {
            padding: 4px 8px;
        }

        .mat-select-arrow-wrapper {
            transform: translateX(-8px);
        }

        .mat-form-field-subscript-wrapper {
            top: calc(100% - 8px);
        }

        &.mat-form-field-appearance-fill {
            .mat-form-field-wrapper {
                border-radius: 18px;
                transition: box-shadow 100ms linear;

                .mat-form-field-flex {
                    padding: 0 4px !important;
                }

                &:hover {
                    box-shadow: 0 0 0 1px inset var(--border-color);
                }

                .mat-form-field-flex {
                    border-radius: 15px;
                }

                .mat-form-field-underline {
                    display: none;
                }

            }

            &.activity-filter .mat-form-field-wrapper {
                border-radius: 10px;
            }

            &.activity-filter {
                &.mat-focused .mat-form-field-wrapper {
                    box-shadow: 0 0 0 1px inset var(--accent-500);
                }


                &.numeric-unit {
                    .mat-form-field-wrapper {
                        .mat-form-field-flex {
                            .mat-form-field-infix {
                                @include flexbox-row--centered();

                                span {
                                    margin-right: 8px;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }


            &.mat-focused .mat-form-field-wrapper {
                box-shadow: 0 0 0 1px inset var(--primary-500);
            }

            &.mat-form-field-invalid .mat-form-field-wrapper {
                box-shadow: 0 0 0 1px inset var(--warn-500);
            }
        } 
    }

    &.not-rounded {
        mat-form-field.mat-form-field-appearance-fill {
            .mat-form-field-wrapper {
                border-radius: 0;
            }
        }
    }

    .clear-button {
        padding: 0;
        min-width: 20px;
        width: 20px;
        height: 20px;
        position: relative;
        color: var(--text-medium);
        left: 0;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        align-items: center;
        margin-left: 2px;

        mat-icon {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
        }
    }

    &.column-field {
        .mat-form-field-flex {
            height: unset;
        }

        .clear-button {
            position: absolute;
            top: 1px;
            left: calc(100% - 38px);
            height: 20px;
            width: 20px;
            min-width: 20px;
            background: unset !important;

            mat-icon {
                height: 20px;
                line-height: 20px;
                font-size: 20px;
            }
        }
    }

    &.column-field-kanban-edit {
        .mat-form-field-flex {
            height: unset;
        }
    }

    app-country-selector,
    app-activity-selector {
        display: flex;
        align-items: center;
    }
}

.activity-field-kanban-edit {
    & .grid-container-input-with-clear {
        grid-template-columns: 85% auto !important;
    }

    & .grid-container-activity-link {
        grid-template-columns: 32px 100% !important;

        app-activity-selector {
		    @extend .grid-container-input-with-clear;
    	}
    }
}

.default-search-bar {
    mat-form-field {
        .mat-form-field-flex {
            margin: 0;
            height: 42px;
            padding: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            display: flex;
            align-items: baseline;
        }

        .mat-form-field-wrapper {
            padding: 0;
        }

        .mat-form-field-infix {
            border: 0;
            padding: 16px 0;
            display: flex;
            align-items: center;
        }

        .mat-form-field-prefix {
            align-self: center;
        }

        mat-icon {
            display: inline-block !important;
            margin: 0 8px;
            color: var(--text-low);
            font-size: 18px !important;
            height: 18px;
            width: 18px;
        }

        input {
            color: var(--text-high);
        }
    }
}

.feed-post-dialog {
    mat-dialog-container {
        padding: 0;
        overflow-y: auto;

        mat-card {
            width: 712px;
        }
    }
}

.hailer-bounce {
    -webkit-animation: sk-bounce 1.5s infinite ease-in-out;
    animation: sk-bounce 1.5s infinite ease-in-out;
}

@media only screen and (max-width: 800px) {
    .feed-post-dialog {
        mat-dialog-container {
            mat-card {
                width: unset;
            }
        }
    }
}

.no-padding-panel {
    max-height: 400px !important;

    .mat-menu-content {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 600px) {
    .sidebar {
        max-width: 100%;
    }
}

.cdk-overlay-pane {
    .team-options {
        .mat-option-text {
            display: flex;
            justify-content: space-between;
        }

        .bracket {
            display: none;
        }
    }

    .user-count {
        .mat-icon::after {
            content: 'people';
        }
    }
}

.mat-dialog-title {
    color: white !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-family: 'Nunito', sans-serif !important;
    letter-spacing: 0.5px !important;
}

.remove-field-padding {
    .mat-form-field-wrapper {
        padding: 0;
    }
}

.mat-form-field-infix {
    border-top-color: transparent !important;
}

// Global Search - START //
.global-search-menu-panel {
    max-width: 400px !important;
    border-bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .mat-menu-content {
        padding: 0 !important;
        margin-bottom: -1px;
        overflow: hidden;
    }

    .search-item {
        padding: 0;
        height: 50px !important;
    }
}

.global-search-field {
    .mat-form-field-flex {
        padding: 0 .75em !important;

    }

    .mat-form-field-wrapper {
        padding: 0;
    }

    .mat-form-field-infix {
        padding: 16px 0;
        border-top: 0;
    }

    .mat-form-field-underline {
        bottom: 0;
    }
}

.global-search-panel {
    .mat-option-text {
        display: inline-block !important;
    }

    &.mat-autocomplete-panel {
        max-height: unset;
        overflow: hidden;
    }

    .mat-optgroup-label {

        border-bottom: 1px solid var(--border-color);
        color: mat-color($hailer-app-accent, 500);
        font-weight: 700;
        position: sticky;
        top: 0;
        z-index: 1;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
    }

    .mat-option {
        padding: 8px 16px !important;
        height: auto;
        border-bottom: 1px solid var(--border-color);
    }

    .header-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 0 16px;
    }

    .item-with-image {
        display: flex;
        align-items: center;

        img {
            height: 40px;
            margin-right: 8px;
            border-radius: 50%;
        }
    }

    .small-text {
        color: rgba(0, 0, 0, .6);
        font-size: 12px;
    }

    .link-font {
        font-weight: 700;
        color: #00a544;

        &:hover {
            font-weight: 900;
        }
    }

    .vertical-menu-flex {
        display: flex;
        flex-direction: column;
        line-height: 16px;
        font-size: 14px;
    }

    .online-icon {
        height: 14px;
        width: 12px;
        font-size: 12px;
        margin-right: 4px !important;
    }

    .filter-button {
        mat-icon {
            margin: 0;
        }
    }

    .selected-filter {
        mat-icon {
            color: mat-color($hailer-app-accent, 500);
        }
    }

    span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

// Global Search - END //

// Sidenav stuff - START //
.header-button {
    z-index: 1;
    display: flex;
    justify-content: center;
    top: 8px;
    position: absolute;
    background-color: rgba(0, 0, 0, .3);
}

.back-button {
    left: 8px;
}

// Sidenav stuff - END //

// Button stuff //

// Button stuff - END //

// Spinner stuff //

.mat-progress-spinner::after {
    content: '';
    box-shadow: inset 0px 0 0px 2px #00000015;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(0.95);
}

.mat-progress-spinner svg {
    stroke-linecap: round;

    circle {
        stroke-width: 5% !important;
    }
}

// Spinner stuff - END //

.hide-discussion {
    text-align: center;
    margin-top: calc((var(--vh, 1vh) * 100) / 8);
    margin-right: 16px;
    margin-left: 16px;
    font-weight: 600;
    font-size: 18px;
    color: var(--text-low);
}

.linked-search {
    width: calc(100% - 16px);
    margin: 0 8px;
}

:host ::ng-deep .linked-search {
    .mat-form-field-wrapper {
        padding: 8px 0;
    }

    .mat-form-field-infix {
        padding: 8px 0;
        border-top: 8px solid transparent;
    }

    .mat-form-field-outline {
        background: #fff;
        border-radius: 6px;
    }
}

.activity-buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

:host ::ng-deep .linked-search {
    .mat-form-field-wrapper {
        padding: 8px 0;
    }

    .mat-form-field-infix {
        padding: 8px 0;
        border-top: 8px solid transparent;
    }

    .mat-form-field-outline {

        border-radius: 6px;
    }
}

.bigger-tooltip {
    font-size: 1.5em;
}

.team-icon {
    height: 40px;
    width: 40px;
    padding: 8px;
    margin-right: 8px;
    box-sizing: border-box;
    background-color: var(--container-background);
    color: white;
}

.activities-full-calendar .fc-h-event .fc-event-main-frame {
    display: flex;
    flex-direction: column;
}

// User mention styling needs to be here, otherwise style breaks
.user-mention {
    background: none;
    border: none;
    padding: 0;
    color: var(--user-mention-default);
    font-weight: 600;
    font-size: inherit;
    text-align: left;
    white-space: normal;

    &:focus-visible {
        outline: $focus-outline;
        border-radius: $focus-border-radius-small;
    }
}

.activity-event span.fc-title::before {
    font-family: 'hailer-fa-icons';
    content: '';
    font-size: 16px;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    margin: 0px 4px;
}

.activity-event {
    cursor: pointer;
}


.mat-expansion-panel-header {
    &.mat-expanded {
        height: 48px;
    }

    &.tall-header {
        height: 64px;

        .mat-expansion-indicator {
            padding: 8px;
            background: var(--background-primary-alt);
            border-radius: 50%;
            width: 8px;
            height: 8px;
            line-height: 0;
        }
    }
}

// Files styles START

mat-list-item.file-item {
    height: auto !important;
    min-height: 60px;
    max-height: 72px;
    margin-bottom: 5px;
    padding-bottom: 4px !important;

    &:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    span.mat-list-item-content {
        padding: 0 !important;
    }

    p {
        margin: 0;
        padding: 0;
        font-size: 0.75rem;
        color: var(--subtitles);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .file-item-content-right {
        float: right;
        margin-left: auto;

        @include flexbox-column;
        align-items: flex-end;

        .file-item-control-buttons {
            @include flexbox-row;
        }
    }

    .file-item-content-left {
        overflow: hidden;

        @include flexbox-column--center;

        h3 {
            margin: 0;
            padding: 0;
            font-size: 0.8rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

// Files styles END

// Input stuff - START //


// Input stuff - END //
