/* #region green palette */

$mat-hailer-green: (
    50 : #e4f4ea,
    100 : #bce3cb,
    200 : #90d1a8,
    300 : #63be85,
    400 : #41b06a,
    500 : #20a250,
    600 : #1c9a49,
    700 : #189040,
    800 : #138637,
    900 : #0b7527,
    A100 : #a7ffba,
    A200 : #74ff91,
    A400 : #41ff69,
    A700 : #27ff55,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-hailer-dark-green: (
    50 : #e8fff0,
    100 : #c5ffda,
    200 : #9effc2,
    300 : #77ffa9,
    400 : #5aff96,
    500 : #3dff84,
    600 : #37ff7c,
    700 : #2fff71,
    800 : #27ff67,
    900 : #1aff54,
    A100 : #ffffff,
    A200 : #fefffe,
    A400 : #cbffd7,
    A700 : #b2ffc3,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* #endregion */


/* #region blue palette */

$mat-hailer-blue: (
    50 : #e5e8eb,
    100 : #bec5cc,
    200 : #929eab,
    300 : #667789,
    400 : #46596f,
    500 : #253c56,
    600 : #21364f,
    700 : #1b2e45,
    800 : #16273c,
    900 : #0d1a2b,
    A100 : #6ca0ff,
    A200 : #397fff,
    A400 : #065eff,
    A700 : #005ec2,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mat-hailer-dark-blue: (
    50 : #e9eff5,
    100 : #c8d6e5,
    200 : #a4bbd4,
    300 : #7f9fc3,
    400 : #638bb6,
    500 : #4876a9,
    600 : #416ea2,
    700 : #386398,
    800 : #30598f,
    900 : #21467e,
    A100 : #bdd5ff,
    A200 : #8ab5ff,
    A400 : #5795ff,
    A700 : #3d85ff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

/* #endregion */


/* #region red palette */
//Red Palette

$mat-hailer-red: (
    50 : #fce6e0,
    100 : #f7bfb3,
    200 : #f29580,
    300 : #ec6b4d,
    400 : #e84b26,
    500 : #e42b00,
    600 : #e12600,
    700 : #dd2000,
    800 : #d91a00,
    900 : #d11000,
    A100 : #fff9f9,
    A200 : #ffc8c6,
    A400 : #ff9793,
    A700 : #ff7e7a,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-hailer-dark-red: (
    50 : #ffe6e0,
    100 : #ffc1b3,
    200 : #ff9880,
    300 : #ff6e4d,
    400 : #ff4f26,
    500 : #ff3000,
    600 : #ff2b00,
    700 : #ff2400,
    800 : #ff1e00,
    900 : #ff1300,
    A100 : #ffffff,
    A200 : #fff3f2,
    A400 : #ffc2bf,
    A700 : #ffa9a6,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* #endregion */

/* #endregion */
