.event-body,
.event-field {
    position: relative;
    padding: 10px 16px;
    display: flex;

    .title-text {
        font-weight: 700;
        margin: 0;
        padding: 8px 0;
        font-size: 1rem;
    }

    p {
        margin-left: 8px;
        margin: 0;
        overflow-wrap: anywhere; 
    }

    &.date-field {
        background-color: unset;
        padding: 16px;
        display: grid;
        align-items: center;
        justify-items: stretch;
        justify-content: center;

        p {
            font-size: 1rem;
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            justify-items: end;
            grid-gap: 1em;

            span:first-child {
                color: mat-color($hailer-app-accent, 500);
                font-weight: bold;
            }
        }
    }

    .row-field {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-gap: 1em;
        justify-content: center;
        align-items: center;
    }

    .line {
        height: 48px;
        width: 8px;
        position: absolute;
        left: 0;
    }
}

.event-body {
    .attendees {
        margin-top: 8px;

        .username {
            margin-left: 8px;
            font-weight: 400;
            font-size: 16px;
            max-width: 216px;
            word-break: normal;
            flex: 1;
            cursor: default; 
        }

        .attendee {
            margin-top: -1px;
            width: unset;
            height: 56px;

            span.mat-list-item-content {
                display: grid;
                grid-template-columns: auto 1fr auto;
                grid-template-rows: auto;
                grid-gap: 1em;
            }
        }
    }

    .attendee:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .attendee:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .attendees-container {
        width: 100%;
        border-radius: 20px;
    }

    .attendee-label {
        font-weight: 800;
        color: var(--text-medium);
    }

    .checkbox-text {
        font-weight: 800;
        color: var(--text-medium);
    }

    .event-buttons {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        box-shadow: 0px -8px 8px 0 #0000000f;
    }

    .response-buttons {
        width: 100px;
        height: 24px;
        display: flex;
        align-items: center;
        margin-right: 4px;
    }
}
