@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,400,400i,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');

:root {
    font-family: 'Nunito Sans', system-ui, Arial, sans-serif;

    --insight-table-font: 'Lato', system-ui, Arial, sans-serif;

    --insight-table-header-text: #1D334B;
    --insight-table-header-bg: #F3F4F7;
    --insight-table-stripes: #F9FAFD;

    --insight-pivot-row-header: #ffffff;
    --insight-pivot-highlight-row-header: #eef3f7;
    --insight-pivot-highlight-row-header: #F9FAFD;
    --insight-pivot-highlight-row: #F9FAFD;
    --insight-pivot-highlight-col-header: #e3e3e6;
    --insight-pivot-totals-column: #eaf2f9;
    --insight-drilldown-cell: #e3e3e6;

    --insight-table-dark-border:  #e6eaed;
    --insight-table-light-border:  #e6eaed;
    --insight-table-hover: #ebebec;

    --insight-button-bg-primary: #253C56;
    --insight-button-text-primary: #ffffff;

    --insight-button-bg-secondary: #F3F4F7;
    --insight-button-text-secondary: #253C56;

    --insight-button-bg-accent: #17873F;
    --insight-button-text-accent: #ffffff;

    --insight-tooltip-bg: #FFFFFF;
    --insight-tooltip-text: #212121;

    --insight-bg: #eaebf1;
    --insight-text: #212121;
    --insight-hint: #737478;
    --insight-section-bg: #ffffff;
    --insight-topbar-bg: #ffffff;
    --insight-topbar-border: #DEE0E4;
   
    --insight-input-placeholder: #999CA4;
    --insight-input-focus-outline: #17873F;
    --insight-bubble: #20A250;

    --insight-highlight-select-bg: #ffffff;
    --insight-highlight-select-outline: #999CA4;

    --insight-menu-bg: #ffffff;
    --insight-menu-hover: #ebebec;
    
    --insight-list-bg: #ffffff;
    --insight-selected-item: #D9E8F7;

    --insight-warn-text: #E42B00;
    --insight-collapse-border: #DEE0E4;
}

/* #region dark theme */
body.dark-theme {
    --insight-table-header-text: #9fa3ac; 
    --insight-table-header-bg: #151618;
    --insight-table-stripes: #1B1C1F;
   
    --insight-pivot-row-header: #0E0F12; 
    --insight-pivot-highlight-row-header: #050505; 
    --insight-pivot-highlight-row: #1B1C1F; 
    --insight-pivot-highlight-col-header: #231f2c;
    
    --insight-pivot-totals-column: #0E0F12;
    --insight-drilldown-cell: #1a1920;

    --insight-table-dark-border: #2a2b2e;
    --insight-table-light-border:  #323439;
    --insight-table-hover: #0E0F12;

    --insight-button-bg-primary: #9F13F4;
    --insight-button-text-primary: #EDEFF3;

    --insight-button-bg-secondary: #292C33;
    --insight-button-text-secondary: #EDEFF3;

    --insight-button-bg-accent: #17873F;
    --insight-button-text-accent: #EDEFF3;

    --insight-tooltip-bg: #151618;
    --insight-tooltip-text: #EDEFF3;

    --insight-bg: #151618;
    --insight-text: #EDEFF3;
    --insight-hint: #737478;
    --insight-section-bg: #202124;
    --insight-topbar-bg: #202124;
    --insight-topbar-border: #393c41;
  
    --insight-input-placeholder: #999CA4; 
    --insight-input-focus-outline: #17873F; 
    --insight-bubble: #20A250; 

    --insight-highlight-select-bg: #292C33;
    --insight-highlight-select-outline: #393c41;

    --insight-menu-bg: #292C33;
    --insight-menu-hover: #34373F;

    --insight-list-bg: #202124;
    --insight-selected-item: #4D3564;

    --insight-warn-text: #E42B00;
    --insight-collapse-border: #DEE0E4;
}
/* #endregion dark theme */

/* #region topbar */
.insight-topbar {
    position: relative;
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    background-color: var(--insight-topbar-bg); 
    border-bottom: 1px solid var(--insight-topbar-border);
}

.insight-right-side-buttons {
    display: flex;
    position: absolute;
    right: 8px;
}

.insight-title {
    font-size: 1.2rem;
    font-weight: 800;
    line-height: 1;
    margin-left: 16px; 
    max-width: 30vw;
    color: var(--insight-text);
  
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
/* #endregion topbar */

/* #region insights grid */
.insight-container {
    display: grid;
    overflow: auto;
    height: calc(100vh - 50px);
    background-color: var(--insight-bg);
    padding: 10px 10px 0 10px;
    grid-template-columns: 1fr;
    box-sizing: border-box;
}

.insight-container .chart {
    grid-column: 1;
    grid-row: 1;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--insight-section-bg);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.insight-container .pivot-container {
    grid-column: 1;
    grid-row: 2;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--insight-section-bg);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.insight-container .table {
    grid-column: 1;
    grid-row: 3;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.85rem;
    font-family: var(--insight-table-font);
    white-space: nowrap;
    background-color: var(--insight-section-bg);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

/* #endregion insights grid */

/* #region inputs, selects */
.insight-input,
.insight-select {
    font-size: 0.875rem;
    font-family: inherit;
    background-color: var(--insight-button-bg-secondary);
    color: var(--insight-text);
    border-radius: 6px; 
    border: none;
    padding: 6px 8px;
    margin: 4px 8px;
    width: 130px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
  }

.insight-input.highlight-select,
.insight-select.highlight-select {
    background-color: var(--insight-highlight-select-bg);
}

.insight-select.xs-margin {
    margin: 0 0 0 8px;
}

.insight-search-wrapper {
    position: relative;
}

.insight-search {
    margin: 4px;
    padding-left: 32px;
}

.insight-search-icon-topbar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    pointer-events: none;
    color: var(--insight-input-placeholder);
    z-index: 10;
}

.xs-select {
    width: 85px;
}

.transparent-select {
    margin: 0 4px 0 0;
    background: transparent;
    font-weight: 600;
    color: var(--insight-table-header-text);
    vertical-align: super;
    width: 160px;
}

.insight-input::placeholder,
.insight-select::placeholder {
    color: var(--insight-input-placeholder);
}

.insight-input:not([type="checkbox"]):focus, 
.insight-select:not([type="checkbox"]):focus {
    outline: 2px solid var(--insight-input-focus-outline);
}

.insight-select {
    border-right: 10px solid transparent;
}

.wide-insight-input {
    width: 200px;
}
/* #endregion inputs, selects */

/* #region scrollbar */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    min-height: 8px;
}

::-webkit-scrollbar-thumb {
    height: 6px;
    min-height: 50px;
    border: 1px solid transparent;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.6);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
/* #endregion scrollbar */

/* #region chart */

.insight-chart-toggle-wrapper {
    height: calc(100% - 34px);
    display: flex;
    flex-direction: column;
}

/* #endregion chart */

/* #region pivot */

.insight-empty-pivot-wrapper {
    padding: 10px;
}

.insight-container .pivot {
    font-size: 0.85rem;
    font-family: var(--insight-table-font);
    white-space: nowrap;
}

.insight-container table.pivot {
    border-collapse: collapse;
    color: var(--insight-text);
}

.insight-container table.pivot > tbody > tr > th {
    min-width: 25px;
    border: 1px solid var(--insight-table-dark-border);
    text-align: center;
    background-color: var(--insight-table-header-bg);
    color: var(--insight-table-header-text);
    padding: 4px;
}

.insight-container table.pivot > tbody > tr:first-child > th:last-child {
    position: sticky;
    right: 0;
    background-color: var(--insight-pivot-totals-column) !important;
    padding: 4px 8px;
}

.insight-row-totals {
    text-align: right !important;
    background-color: var(--insight-pivot-totals-column) !important;
    position: sticky;
    right: 0;
}

/* This is to hide pivot columns that scroll on the right side of the Totals column that is sticky */
.insight-container table.pivot > tbody > tr > th:last-child::after {
    content: "";
    position: absolute;
    top: 0;
    right: -12px;
    width: 12px;
    height: 100%;
    background-color: var(--insight-section-bg);
}

.insight-container table.pivot > tbody > tr > th.head {
    min-width: 90px;
    /* min-width: 220px; */
    border: 1px solid var(--insight-table-dark-border);
    color: var(--insight-table-header-text);
    position: relative;
    z-index: 2;

}

.insight-container table.pivot > tbody tr .label {
    text-align: left;
    padding: 4px 8px;
 
}

/* data cells */
.insight-container table.pivot > tbody > tr > td {
    border: 1px solid var(--insight-table-light-border);
    text-align: center;
    cursor: pointer;
}

/* .insight-container table.pivot > tbody > tr > td:hover { */
    /* background-color: var(--insight-table-hover) !important; */
    /* background-color: var(--insight-table-header-bg) !important; */
/* } */

/* footer */
.insight-container table.pivot > tfoot > tr > th {
    border: 1px solid var(--insight-table-dark-border);
    background-color: var(--insight-table-header-bg);
    color: var(--insight-table-header-text);
    padding: 4px;
    border-collapse: collapse;
}

.insight-container table.pivot > tfoot {
    position: sticky;
    bottom: 0;
}

.insight-container table.pivot > tfoot > tr > th:last-child {
    position: sticky;
    right: 0;
    text-align: right;
    background-color: var(--insight-pivot-totals-column) !important;
}

/* To hide pivot columns that scroll on the right side of the footer */
.insight-container table.pivot > tfoot > tr > th:last-child::before {
    content: "";
    position: absolute;
    top: 0;
    right: -12px;
    width: 12px;
    height: 100%;
    background-color: var(--insight-section-bg);
}

/* To hide pivot rows that scroll below the footer */
.insight-container table.pivot > tfoot > tr > th::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    right: -1px;
    height: 13px;
    background-color: var(--insight-section-bg);
}

.insight-container table.pivot > tfoot > tr > th:first-child {
    text-align: right;
    padding-right: 8px;
    color: var(--insight-table-header-text);
    /* height: 23px; */
}

.insight-warn {
    color: var(--insight-warn-text);
}

.footer-totals {
    vertical-align: top;
}

.footer-arrow {
    vertical-align: sub;
    margin-left: 8px;
}

.insight-col-head {
    z-index: 1 !important;
}

.insight-row-head {
    background-color: var(--insight-pivot-row-header) !important;
}

.highlighted-row-header {
    background-color: var(--insight-pivot-highlight-row-header) !important;
}

.highlighted-row,
.highlighted-col {
    background-color: var(--insight-pivot-highlight-row) !important;
}

.highlighted-col-header {
    background-color: var(--insight-pivot-highlight-col-header) !important;
}

.drilldown-cell {
    background-color: var(--insight-drilldown-cell) !important;
}

.totals-drilldown-cell {
    background-color: var(--insight-pivot-highlight-col-header) !important;
}

.selected-value {
    font-weight: 600;
    color: var(--insight-table-header-text);
    vertical-align: super;
    margin: 0 8px;
}

/* #endregion pivot */

/* #region table */
.insight-container table.list {
    border-collapse: collapse;
    color: var(--insight-text);
}

.insight-table-empty {
    cursor: default !important;
    color: var(--insight-hint);
    font-weight: bold;
}

.insight-table-empty:hover {
    background-color: var(--insight-section-bg);
}


.insight-container table.list > thead {
    position: sticky;
    top: 0;
}

/* This is to hide table rows that scroll above the header  */
.insight-container table.list > thead::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 10px;
    background-color: var(--insight-section-bg);
}

.insight-container table.list > thead > tr > th {
    /* vertical-align: top; */
    text-align: left;
    background-color: var(--insight-table-header-bg);
    padding: 10px 8px;
    color: var(--insight-table-header-text);
}

.filter-on-th {
    min-width: 160px;
}

.insight-container table.list > tbody > tr > td {
    padding: 8px;
    cursor: pointer;
}

.insight-container table.list > tbody > tr:nth-child(even) {
    background-color: var(--insight-table-stripes);
}

.insight-container table.list > tbody > tr:hover {
    background-color: var(--insight-table-hover);
}

.insight-rows-and-drill-down-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.insight-container .cell-value {
    max-width: 20rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.insight-container .cell-value-type-number {
    text-align: right;
}

.insight-container .cell-value-type-string {
    text-align: left;
}

.insight-filter-wrapper {
    padding: 6px 0 0 0;
    min-height: 40px;
    max-width: 350px;
    align-items: center;
}

.insight-comparison-filter {
    margin-bottom: 2px;
}

.insight-comparison-filters-wrapper {
    margin: 6px 0 0 0;
}

.insight-comparison-filters-wrapper input {
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-radius: 4px;
    padding: 6px 8px;
    margin-bottom: 4px;
    font-size: 0.875rem;
}

.insight-comparison-filters-wrapper input:focus {
    outline: 2px solid var(--insight-input-focus-outline);
}

.insight-no-filter-text {
    display: block;
    padding: 10px 0;
    color: var(--insight-hint);
}

.insight-table-topbar {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-weight: bold;
}

.insight-table-topbar form {
    display: flex;
    align-items: center;
}

.insight-table-link {
    font-family: inherit;
    text-decoration: none;
    height: 32px;
    border-radius: 6px;
    border: none;
    padding: 4px 10px;
    font-weight: 700;
    cursor: pointer;
    background-color: var(--insight-button-bg-accent);
    color: var(--insight-button-text-primary) !important;
    flex-shrink: 0;
    font-size: 0.9rem;
}

.insight-table-link:hover{
    filter: brightness(0.95);
    text-decoration: none !important;
}

.insight-table-link:active{
    filter: brightness(0.9);
    text-decoration: none !important;
}

.insight-table-link:focus-visible{
    outline-offset: 2px;
    outline: 2px solid var(--insight-input-focus-outline);
    text-decoration: none !important;
}

.insight-label {
    font-size: 0.85rem;
    font-weight: 700;
    margin-left: 8px;
    display: inline-block;
    color: var(--insight-text);
    z-index: 1;
}

.insight-label.columns-label {
    margin-right: 8px;
}

.insight-header-sm {
    font-size: 1rem;
    font-weight: 700;
    margin-left: 8px;
    margin-bottom: 12px;
    display: inline-block;
    color: var(--insight-text);
}

.sm-insight-input {
    width: 90px;
    height: 32px;
}

.insight-container table.list > tfoot > tr > th {
    background-color: var(--insight-table-header-bg);
    color: var(--insight-table-header-text);
    padding: 8px;
    /* border-collapse: collapse; */
    position: sticky;
    bottom: 0;
}

/* To hide table rows that scroll below the footer  */
.insight-container table.list > tfoot > tr > th::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    height: 12px;
    background-color: var(--insight-section-bg);
}

.insight-table-header-arrow {
    vertical-align: middle;
    margin-left: 4px;
}

.insight-table-header-arrow-wrapper {
    height: 16px;
    user-select: none;
}

/* #endregion table */

/* #region tooltip */
.insight-tooltip {
    position: relative;
    display: inline-block;
  }
  
.insight-tooltip .insight-tooltip-text {
    visibility: hidden;
    white-space: nowrap;
    background-color: var(--insight-tooltip-bg);
    color: var(--insight-tooltip-text);
    font-size: 0.875rem;
    font-weight: 500;
    text-align: left;
    border-radius: 8px;
    padding: 4px 8px;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
}

.insight-tooltip:hover .insight-tooltip-text {
    visibility: visible;
    opacity: 1;
}
/* #endregion tooltip */

/* #region buttons */
.insight-button {
    font-family: inherit;
    height: 32px;
    border-radius: 6px;
    border: none;
    margin: 4px;
    padding: 0 10px;
    font-weight: 700;
    cursor: pointer;
    background-color: var(--insight-button-bg-secondary);
    color: var(--insight-button-text-secondary);
    flex-shrink: 0;
    font-size: 0.9rem;
}
  
.insight-button:hover {
    filter: brightness(0.95);
}

.insight-button:active {
    filter: brightness(0.9);
}

.insight-button:focus-visible {
    outline-offset: 2px;
    outline: 2px solid var(--insight-input-focus-outline);
}

.insight-primary-button {
    background-color: var(--insight-button-bg-primary);
    color: var(--insight-button-text-primary);
}

.insight-accent-button {
    background-color: var(--insight-button-bg-accent);
    color: var(--insight-button-text-accent);
}

.insight-button-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 0 10px 0 6px;
}

.icon-on-right {
    padding: 0 6px 0 10px;
}

.insight-icon-button {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.insight-button-toggled {
    background-color: var(--insight-button-bg-primary);
    color: var(--insight-button-text-primary);
}

.insight-button .icon {
    font-size: 20px;
    line-height: normal;
}

.insight-container .settings .insight-button {
    margin: 10px 8px 0 8px;
}

.insight-button.round {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

.insight-button.sm-button {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.insight-button.sm-button .material-symbols-outlined {
    font-size: 20px;
}

.table-filter-button {
    margin-left: 16px;
}

.insight-clear-filters-icon:hover {
    opacity: 0.7;
}

.close-drilldown-btn {
    margin-bottom: -4px !important;
}

/* #endregion buttons */

/* #region export-menu */

.insight-export-menu-container {
    position: relative;
    display: inline-block;
}

.insight-add-button-menu-container {
    display: inline-block;
}

 /* TODO: rename classes, should not be exclusive to export */
 .insight-export-menu-options-container {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    padding: 6px 0;
    z-index: 10;
    background-color: var(--insight-menu-bg);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    width: fit-content;
    display: flex;
    flex-direction: column;
}

.insight-export-menu-options-container.small-container {
    overflow: auto;
}

.insight-export-menu-options-container button {
    background-color: var(--insight-menu-bg);
    color: var(--insight-text);
    border: none; 
    font-size: 0.875rem;
    text-align: left;
    white-space: nowrap;
    height: 40px;
    padding: 0px 16px;
    cursor: pointer; 
}

.insight-export-menu-options-container button:hover {
    background-color: var(--insight-menu-hover); 
}

.insight-filter-menu-container {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    padding: 6px 0;
    z-index: 10;
    background-color: var(--insight-tooltip-bg);
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    width: fit-content;
    display: flex;
    flex-direction: column;
}

.insight-filter-menu-container button {
    border: none; 
    font-size: 0.875rem;
    text-align: left;
    white-space: nowrap;
    height: 32px;
    padding: 0px 16px;
    cursor: pointer; 
}

button.insight-small-menu-item {
    height: 26px;
    min-height: 26px;
}

/* #endregion export-menu */

.pointer-cursor {
    cursor: pointer;
    user-select: none;
}

.insight-filter-menu {
    padding: 6px 4px;
}

.material-symbols-outlined.small {
    font-size: 20px;
}

/* #region bubble indicator */

.insight-button {
    position: relative;
}

.material-symbols-outlined.bubble::after {
    content: '';
    background: var(--insight-bubble);
    height: 12px;
    width: 12px;
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
}

/* #endregion bubble indicator */

.pivot-value-wrapper {
    margin-left: 8px;
    font-size: 0.85rem;
}

.insight-pivot-topbar {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.custom-insight-react-menu {
    font-size: 0.875rem;
    font-weight: 600;
    font-family: 'Nunito Sans', system-ui, Arial, sans-serif;
    color: var(--insight-text);
}

.custom-insight-react-menu.sm-button {
    position: inline-block;
}

.loading-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20vh;
    box-sizing: border-box;
}

