@use '../../global/base/typography' as typography;

// Material icons
$material-design-icons-font-path: '../../../../material-design-icons-iconfont/dist/fonts/';
@import '../../../../node_modules/material-design-icons-iconfont/dist/material-design-icons';

/* #region variables */
$mat-menu-height: 40px !important;
/* #endregion */

/* Hack to override Angular material's menu's max width */
.override-mat-menu.mat-menu-panel {
    max-width: 400px;
}

.mat-menu-panel {
    border-radius: 8px !important;
    min-height: 40px !important;
    max-height: 80vh !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1), 0px 4px 12px rgba(0, 0, 0, 0.15) !important;

    &.full-width-menu {
        max-width: none !important;
    }
}

.mat-menu-item {
    line-height: 40px !important;
    height: $mat-menu-height;
    letter-spacing: 0.03em;
    font-size: 0.875rem !important;
    color: var(--menu-text) !important;

    &[disabled] {
        color: var(--small-text-gray) !important;
    }
}

div > .mat-dialog-container {
    height: unset;
    overflow: hidden;
    border-radius: 12px;
}

.mat-tooltip {
    font-size: 12px;
    border-radius: 8px !important;
    letter-spacing: 0.5px;
    background: #000e20;
    padding: 4px;
}

.mat-snack-bar-container {
    background: var(--container-background) !important;
    color: white !important;

    .mat-simple-snackbar-action button {
        color: #6affa1 !important;
    }
}

snack-bar-container.mat-snack-bar-container {
    border-radius: 20px !important;

    button {
        color: mat-color($hailer-app-accent, 500);
        font-weight: 700;
        border-radius: 20px;
    }
}

snack-bar-container.warning-snackbar {
    background: mat-color($hailer-app-warn, 500);

    button {
        color: mat-contrast($hailer-app-warn, 500);
    }
}

mat-ink-bar,
.mat-ink-bar {
    background-color: var(--accent-400) !important;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled='true']),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled='true']),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
    background: var(--activity-filter-hover) !important;
}

.mat-focus-indicator.mat-tab-label.mat-tab-label-active {
    color: var(--active-icon) !important;
}

.mat-focus-indicator.mat-tab-label {
    color: var(--inactive-icon) !important;
}

.mat-accordion > .mat-expansion-panel-spacing:last-child {
    margin-bottom: 1rem !important;
}

mat-list-item.discussion-link-item {
    .mat-list-item-content {
        padding: 0 !important;
    }
}

mat-option.dropdown-option {
    min-height: min-content;
    height: $mat-menu-height;
    line-height: normal !important;
}

