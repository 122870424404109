/* this mixin is only to be used until SCSS refactoring occurs.
then will not need to include() it into any style sheets, will automatically be there */
@mixin error {

    .error-popup {
        transition: opacity 500ms linear;
        position: absolute;
        background-color: var(--warn-text);
        height: 32px;
        bottom: $topbar-height;
        left: 0;
        right: 0;
        z-index: 10;

        @include flexbox-row--center-centered();

        span {
            color: white;
            font-weight: 700;
        }

        button {
            z-index: 1;
            position: absolute;
            right: 0;
            height: 32px;
            color: white;
            width: 32px;

            mat-icon {
                line-height: 14px;
            }
        }
    }


    .sidenav-error-container {
        background-color: var(--container-background);
        max-width: 100%;
        height: 56px;
        color: white;
        padding: 0 8px;

        @include flexbox-row--space-between-centered();
    }


    .error-text {
        display: block;
        font-family: 'Nunito', sans-serif;
        font-size: 24px;
        font-weight: 600;
        color: var(--text-low);
        display: block;
        margin: 64px 0 32px;
    }

    .error-img {
        pointer-events: none;
        margin: 16px 32px;
    }

    .close-error-buttons {
        margin-top: 33%;

        @include flexbox-row-reverse--space-between();

        mat-icon {
            margin-right: 8px;
        }
    }

}



.popover-container {
    background: var(--background-primary);
    border-radius: 8px;
    padding: 4px;
    font-size: 14px;
    color: var(--text-high);
    max-width: 280px;
    text-align: center;

    a {
        color: #a4c1ff;

        &:link,
        &:visited {
            text-decoration: none;
        }

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }
}

.note-container {
    margin-top: 16px; 
    padding: 16px 12px;
    background: var(--note-background);
    border-radius: 10px;

    .note-title-container {
        margin-bottom: 4px;
    }

    .note-title {
        margin: 0 6px 0 0;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1rem;
    }

    .note-content {
        margin: 0; 
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1rem;
        word-break: keep-all;

        &.increased-font-size {
            font-size: 0.9rem;
            line-height: 1.1rem;
        }
    }
}

.popup-header-close-button-wrapper {
    float: right;
    margin-left: auto;
    margin-right: 12px; 
}