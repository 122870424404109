/** @format */

@import 'variables';
@import '../global/base/typography';
@import 'flexbox';
@import 'hovers';
@import './icons';
@import '../global/mixins';

$min-button-height: 32px;
$max-button-height: 48px;
$large-icon-button-size: 40px;
$round-border-radius: 50%;
$rounded-edges-border-radius: 30px;

$focus-outline: 2px solid var(--focus-outline);
$focus-outline-light: 2px solid var(--focus-outline-light);
$focus-outline-offset: 2px;
$focus-border-radius-small: 2px;

.button-hover {
    filter: brightness(0.95);
}

.sidenav-back-arrow {
    background-color: var(--sidenav-back-button-bg);

    mat-icon[svgIcon='hailer-tailed-arrow'] {
        width: 25px;
        height: 25px;
        transform: rotate(90deg);
    }

    &_left {
        mat-icon[svgIcon='hailer-tailed-arrow'] {
            width: 25px;
            height: 25px;
            transform: rotate(-90deg);
        }
    }
}

.filter-button {
    padding: 8px 16px;
    background-color: var(--background-primary);
    border-top: 1px solid var(--border-color);
    position: absolute;
    bottom: 1px;
    right: 0;
    left: 0;
    z-index: 200;

    @include flexbox-row--center-centered();

    mat-icon[svgicon='hailer-x'] {
        margin-right: 10px;
        color: var(--warn-icon);
    }
}

@mixin checkbox-thin {
    border-radius: 3px;
    border-color: var(--grey-400);
    border-width: 1.5px;

    .mat-checkbox-frame,
    .mat-checkbox-background {
        border-radius: 3px;
    }

    .mat-checkbox-frame {
        border-color: var(--grey-400);
        border-width: 1.5px;
    }

	.mat-checkbox-checkmark-path {
		stroke: var(--white) !important;
	}

    &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
        background-color: var(--bubble-bg);
        opacity: $disabled-opacity;
    }

    &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-frame {
        border-color: transparent;
        opacity: $disabled-opacity;
    }

    &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
        background-color: var(--checkbox-bg);
        opacity: $disabled-opacity;
    }
    &.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-frame {
        border-color: transparent;
        opacity: $disabled-opacity;
    }

    .mat-checkbox-mixedmark {
        background-color: var(--white) !important;
    }

    &.mat-checkbox-checked .mat-checkbox-background,
    &.mat-checkbox-indeterminate .mat-checkbox-background {
        background-color: var(--checkbox-bg) !important;
     }
}

.checkbox-thin {
    @include checkbox-thin();
}

mat-pseudo-checkbox .mat-pseudo-checkbox {
    @include checkbox-thin();
}

.no-button {
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;

    &.for-icon {
        height: 24px;
        width: 24px;
        padding: 0;
        margin: 0 0 0 8px;
        @include flexbox-row--centered();
        align-self: center;

        &.add-linked-activity-button {
            margin: 0 !important;
            color: var(--text-medium) !important;
        }

        &.add-button {
            color: var(--accent-500);
        }
    }

    &.sidenav-icon-button,
    &.large-round {
        width: $large-icon-button-size;
        height: $large-icon-button-size;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $round-border-radius;
    }
    &.sidenav-icon-button {
        margin: 0;
    }

    &.large-round {
        @include ripple-hover();
    }

    &.for-text-and-icon {
        display: flex;
        align-items: center;
        margin: 0 8px 0 0;

        span {
            color: var(--text-high);
        }

        &.add-button {
            color: var(--accent-500);
            border: $accent-default-border;
            border-radius: $rounded-edges-border-radius;
            padding: 0 8px;
        }
    }

    &.hailer-x {
        &-warn {
            color: var(--warn-500);
        }
    }

    &.for-text {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $rounded-edges-border-radius;

        &.primary {
            border: $accent-default-border;
            color: var(--accent-500);
        }
    }

    &.primary {
        color: var(--primary-500);
    }
}

.radio-button {
    border: $divider-light-border;
    border-radius: 50%;
    margin: 2px;
    width: 16px;
    height: 16px;

    &.disabled {
        opacity: $disabled-opacity;
    }

    &.active {
        background-color: var(--accent-500);
        border: $accent-default-border;
        box-shadow: 0 0 0 2px var(--radio-button-inset) inset;

        &.disabled {
            opacity: $disabled-opacity;
        }
    }
}

.icon-button {
    cursor: pointer;
    border-color: transparent;
    background-color: transparent;
    color: var(--text-medium);

    &.round {
        border-width: 1.5px;
        border-style: solid;
        border-radius: 100%;
        border-color: transparent;

        &--grey {
            fill: var(--icon-color);
            color: var(--icon-color);
            border-color: var(--grey-400);
        }
    }

    &.active {
        border-color: var(--grey-400);
        background-color: var(--grey-400);
    }

    &.add-new {
        border-color: var(--accent-500);
        background-color: var(--accent-500);
    }

    &.add-new-user {
        background-color: var(--primary-500);
        color: var(--white);
        border-radius: $round-border-radius;
    }

    &.remove {
        border-color: var(--blue-700);
        background-color: var(--blue-700);
        height: 10px;
        width: 10px;
    }

    .mat-icon {
        fill: var(--icon-color);
        color: var(--icon-color);

        @extend mat-icon;
    }
}

/* create mixin for round that sets the color */

.round {
    border: 1.5px solid var(--grey-400);
    border-radius: 100%;

    &--grey-background {
        border-color: var(--grey-400);
        background-color: var(--grey-400);
    }
}

button.mat-stroked-button,
button.mat-flat-button,
button.mat-button,
button.mat-raised-button,
mat-chip.mat-standard-chip,
button.mat-button-toggle-button,
a.mat-flat-button {
    @extend .button-font;
    min-height: $min-button-height;
    max-height: $max-button-height;
    border-radius: 20px;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    justify-content: center;

    > span.mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.filters-active-button {
        background: var(--activity-filter-hover);
    }

    &.save-button {
        color: var(--accent-text);
    }

    &.cancel-button {
        color: var(--primary-text);
    }

	&.primary-public-pages {
		width: 100%;
		max-width: 330px;
		height: 40px;
		background-color: var(--primary-btn-bg-public-pages);
		color: var(--white);
		color: #212121;
        font-weight: 700 !important;

        &.semi-wide {
            width: 250px !important;
        }

        &:hover {
            filter: brightness(0.8);
            text-decoration: none;
        }

		&.mat-button-disabled {
			color: #212121 !important;
            background-color: var(--label-public-pages);

            &:hover {
                filter: none; 
            }
		}
	}

	&.tertiary-highlight-public-pages {
		width: 100%;
		max-width: 330px;
		height: 40px;
		background-color: transparent;
		color: var(--tertiary-btn-text-public-pages);
        font-weight: 700 !important;
        border: 1px solid #686868;

        backdrop-filter: blur(10px);

        &.semi-wide {
            width: 250px !important;
        }

        &:hover {
            filter: brightness(1.2);
            text-decoration: none;
        }
	
		&.mat-button-disabled {
			opacity: $disabled-opacity;
            color: var(--tertiary-btn-text-public-pages) !important;
			background-color: transparent;

            &:hover {
                filter: none;
            }
		}

		&.warn-text {
			color: var(--warn-public-pages);
            border-color: var(--warn-public-pages) !important;
		}
	}

    &.stroke-accent {
        width: 100%;
        max-width: 330px;
        height: 44px;
        color: var(--accent-500);

        &.mat-button-disabled {
            opacity: $disabled-opacity;
        }
    }
}

mat-chip.mat-standard-chip {
    font-size: 10px !important;
}

button.mat-stroked-button[mat-stroked-button] {
    border-color: unset !important;
    border: 2px solid var(--border-color);

    &.mat-accent:not([disabled]) {
        color: var(--accent-text);
        border: 2px solid var(--accent-text) !important;
    }

    &.mat-primary:not([disabled]) {
        color: var(--primary-text);
        border: 2px solid var(--primary-text) !important;
    }
}

button.mat-button[mat-button] {
    &.mat-accent:not([disabled]) {
        color: var(--accent-text);
    }

    &.mat-primary:not([disabled]) {
        color: var(--primary-text);
    }
}

button.single-row-button {
    min-width: 240px;
    margin: 0 auto;
    display: block;
}

.control-buttons {
    background: var(--mat-card);
    border-top: $divider-default-border;
    height: auto;
    display: flex;
    align-items: center;

    .save-button,
    .cancel-button {
        width: 100%;

        mat-icon {
            margin-right: 14px;
        }

        &.mat-button-disabled {
            opacity: $disabled-opacity;
        }
    }

    .save-button {
        &-fill {
            @extend .no-button;
            color: var(--accent-500);
            border: $accent-default-border;
            border-radius: $rounded-edges-border-radius;
            min-height: $min-button-height;
            max-height: $max-button-height;
            min-width: 35%;
        }
    }

    .cancel-button {
        &-fill {
            @extend .no-button;
            color: var(--warn-500);
            min-height: $min-button-height;
            max-height: $max-button-height;
            min-width: 35%;
        }
    }
}

.remove-button {
    cursor: pointer;
    border: $warn-default-border;
    border-radius: $rounded-edges-border-radius;
    background-color: transparent;
    color: var(--warn-text);
    width: 50%;
    min-height: $min-button-height;
    max-height: $max-button-height;

    &-wrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

.upload-button {
    background: var(--add-button);
    margin-right: 8px;
    color: var(--white);

    &.mat-button-disabled {
        opacity: $disabled-opacity;
        color: var(--mat-card) !important;
    }
}

.upload-button-with-text {
    width: 350px;
    height: $large-icon-button-size;
    background-color: var(--add-button) !important;
    color: var(--mat-card);

    &.mat-button-disabled {
        opacity: $disabled-opacity;
        color: var(--mat-card) !important;

        .mat-icon {
            cursor: default;
        }
    }
}

.button-solid-large-text {
    @extend .ns-button-bold-1;
    border-radius: 30px !important;
    height: 44px;
    width: 50%;
    max-width: 350px;

    &.solid-ok-button {
        background-color: var(--accent-500);
        color: var(--mat-card);

        &.mat-button-disabled {
            opacity: $disabled-opacity;
            color: var(--mat-card) !important;

            .mat-icon {
                cursor: default;
            }
        }
    }

    &.solid-cancel-button {
        background-color: var(--background-primary-alt);
        color: var(--text-high);

        &.mat-button-disabled {
            opacity: $disabled-opacity;
            color: var(--mat-card) !important;

            .mat-icon {
                cursor: default;
            }
        }
    }
}

/* #region app-button wrapper classes */
.popup-two-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 12px;
}

.popup-single-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.sidenav-buttons-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--mat-card);
    z-index: 10;
    align-items: center;
    border-top: 1px solid var(--dividers);
    justify-content: center;
}

/* #endregion */
