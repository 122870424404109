@import 'hailer-colors';

/* #region fixed sizes */
$sidebar-width: 400px;
$topbar-height: 56px;

$topbar-height-small-screens: 72px;
$topbar-height-push-down-container: 48px;


$large-space-between-elements: 16px;
$med-space-between-elements: 8px;
$small-space-between-elements: 4px;

$align-activity-inputs: 22px;

$button-height: 32px;
$icon-size: 24px;
$icon-size-small: 16px;
$icon-size-small-relative: 1rem;
/* #endregion */

$small-space-between-elements: 4px;
$align-activity-inputs: 22px;
$element-gap: 8px;

/* #endregion */

/* #region shadows */

$box-shadow-light: -2px 1px 2px 0px rgba(0, 0, 0, 0.15);

/* #endregion */

/* #region borders */

$default-border: 1px solid var(--border-color);
$accent-default-border: 2px var(--accent-500) solid;
$warn-default-border: 2px var(--warn-500) solid;
$divider-light-border: 2px var(--grey-400) solid;
$divider-default-border: 1px var(--dividers) solid;
$divider-thin-light: 1px var(--grey-400) solid;

/* #endregion */

/* #region border size and radius */
$thin-border-width: 1.5px;
$thin-border-radius: 3px;
$round-border-radius: 50%;
$rounded-edges-border-radius: 30px;

/* #endregion */

$disabled-opacity: 0.3;
$input-focus-outline:  2px solid var(--accent-500);
$focus-outline: 2px solid var(--focus-outline);

.disabled{
	opacity: $disabled-opacity;
    cursor: default !important;
}

/* #region classes */

.no-margin{
    margin: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.margin-right-small {
    margin-right: $small-space-between-elements !important;
}

.margin-right-medium {
    margin-right: $element-gap !important;
}

.margin-right-large {
    margin-right: $large-space-between-elements !important;
}

.margin-left-small {
    margin-left: $small-space-between-elements !important;
}

.margin-left-medium {
    margin-left: $element-gap !important;
}

.margin-left-large {
    margin-left: $large-space-between-elements !important;
}

.margin-bottom-small {
    margin-bottom: $small-space-between-elements !important;
}

.margin-bottom-medium {
    margin-bottom: $element-gap !important;
}

.margin-bottom-large {
    margin-bottom: $large-space-between-elements !important;
}

.margin-top-small {
    margin-top: $small-space-between-elements !important;
}

.margin-top-large {
    margin-top: $large-space-between-elements !important;
}

.margin-top-medium {
    margin-top: $element-gap !important;
}

.padding-left-small {
    padding-left: $small-space-between-elements !important;
}

.gap-medium {
    gap: $element-gap !important;
}

.half-width {
    width: 50%;
}

.third-width {
    width: 33%;
}

.full-width {
    width: 100%;
}

.pull-right {
    float: right;
    margin-left: auto;
}

.border-bottom {
    border-bottom: $default-border;
}

.align-activity-inputs {
    padding-right: $align-activity-inputs;
}

.rotate-180-deg {
    transform: rotate(180deg);
}

.no-flex-shrink {
    flex-shrink: 0;
}

/* #endregion */

%empty-state {
    margin: 5vh auto 0 auto;
    height: 120px;
    width: 120px;
    background-repeat: no-repeat;
}

.empty-state-text {
    margin: 10px;
    text-align: center;
    font-weight: 400;
    font-size: 0.95rem;
    color: var(--small-text-gray);

    &:first-letter {
        text-transform: uppercase;
    }
}

.no-permissions {
    .mat-column-select {
        display: none;
    }
}

.white {
  color: white;
}

.grey-low{
  color: var(--grey-400)
}

.warn,
.warning {
  color: var(--warn-500)
}


.no-list{
  list-style: none;
}

.clickable{
  cursor: pointer;
}

.playground-code {
    font-family: Consolas, "courier new";
    color: var(--text-high);
    background-color: var(--side-nav-bg);
    padding: 2px;
    font-size: 105%;
}
